import React from "react";
import { useState } from "react";
import { connect } from "react-redux";
import { Endpoints } from "../../API/Endpoints";
import { FetchApi } from "../../API/FetchApi";
import { useEffect } from "react";
import { formatTime, monthNames } from "../../Utils/constants";
import ReactStars from "react-rating-stars-component";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ShareModal from "../../Components/ShareModal";
import { Calendar, momentLocalizer } from "react-big-calendar";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import moment from "moment";
import CustomToolbar from "../../Components/CustomToolBar";
const localizer = momentLocalizer(moment)

function Schedule(props) {
    const [scheduleList, setScheduleList] = useState([]);
    const [updatedScheduleList, setUpdatedScheduleList] = useState([]);
    const history = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [currentView, setCurrentView] = useState('month');
    const [date, setDate] = useState(new Date());
    const [eventDetails, setEventDetails] = useState({
      is_marked_availability: 0,
      id: null,
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: ''
    });

    console.log('sche___', scheduleList);
    const parseDate = (dateStr, timeStr) => {
        return new Date(`${dateStr}T${timeStr}`);
    };

    

    const formattedData =
        // scheduleList?.map((item) => ({
        updatedScheduleList?.map((item) => ({
            title: item?.availability?.event_name,
            // start: `${item?.availability_date}T${item?.time_slot}`,
            // end: `${item?.availability_date}T${item?.time_slot_end}`,
            start: parseDate(item.availability_date, item.time_slot),
            end: parseDate(item.availability_date, item.time_slot_end),
            id: item?.id,
            total_amount: item?.total_amount,
            booking_against: item?.booking_against,
            coach_facility: item?.coach_facility
        }));
  
    const handleViewChange = (view) => {
      setCurrentView(view);
    };

    console.log('frmmmdttt__', formattedData);

  
    const CustomDateCellWrapper = ({ value, children }) => {
      const isPast = value < new Date();
      return React.cloneElement(React.Children.only(children), {
        style: {
          ...children.style,
          backgroundColor: isPast ? '#f0f0f0' : 'inherit',
          color: isPast ? '#d3d3d3' : 'inherit',
          pointerEvents: isPast ? 'none' : 'auto',
        },
      });
    };
  
    const CustomEvent = ({ event, view }) => {
      return (
        <div>
          <strong style={{fontSize: '14px'}}>{event.title}</strong>
          {
            // event?.slot_type == 'fully' &&
            <div className={view=="month"?"py-0":"py-1"} style={{ fontSize: '10px', color: '#888' }}>
              {"with "+ event?.coach_facility?.name}
            </div>
          }
        {view!="month"&&
        <div className={view=="month"?"py-0":"py-1"} style={{ fontSize: '10px', color: '#888' }}>
            {"$"+event?.total_amount.toLocaleString("ja-JP")}
        </div>}
          {/* {event?.selected_amenity && <p style={{fontSize: '10px'}}>{event?.selected_amenity?.amenity}</p>} */}
        </div>
      );
    };

    const handleSelectEvent = (event) => {
        setCurrentView('day');
        setDate(event.start);
      };
    const getScheduleList = async (dateString) => {
        if (props.user && props.user.user_role && (props.user.user_role.name != 'Facilities' && props.user.user_role.name != 'Coach')) {
            try {
                const dateObject = dateString ? new Date(dateString) : ''
                const postURL = dateObject ? Endpoints.getBookings + `?status=all&bookingData=my&filterDate=${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}/${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}/${dateObject.getFullYear()}` : Endpoints.getBookings + `?status=all&bookingData=my`
                let resp = await FetchApi(postURL);
                if (resp && resp.type === "success") {
                    let data = resp.data;
                    data.forEach((item) => {
                        const booked_date = new Date(item.availability_date)
                        item['bookedDate'] = `${booked_date.getDate()} ${monthNames[booked_date.getMonth()]} ${booked_date.getFullYear()}`
                        item['start'] = item.time_slot ? formatTime(item.time_slot, true) : ''
                        item['end'] = item.time_slot_end ? formatTime(item.time_slot_end, true) : ''
                        if (props.user && props.user.user_role && props.user.user_role.name === 'Coach') {
                            item['image'] = item.coach_facility.image
                            item['name'] = item.coach_facility.name
                            item['user_sports'] = item.coach_facility.user_sports.name
                            item['user_star'] = item.coach_facility.reviews_rating;
                            item['address'] = item.coach_facility.address
                        }
                        else {
                            item['image'] = item.coach_facility.image
                            item['name'] = item.coach_facility.name
                            item['user_sports'] = item.coach_facility.user_sports.name
                            item['user_star'] = item.coach_facility.reviews_rating;
                            item['address'] = item.coach_facility.address
                        }
                    })
                    return new Promise((resolve, reject) => {
                        resolve(resp.data)
                    })
                }
            }
            catch (e) {
                // console.log('AvailabilityList', JSON.stringify(e, null, 4));
                return new Promise((resolve, reject) => {
                    resolve([])
                })
            }
        }
    }
    const getScheduleListByAt = async (dateString) => {
        if (props.user && props.user.user_role && (props.user.user_role.name === 'Facilities' || props.user.user_role.name === 'Coach')) {
            try {
                const dateObject = dateString ? new Date(dateString) : ''
                const postURL = dateObject ? Endpoints.getBookings + `?status=all&bookingData=athelete&filterDate=${dateObject.getDate() < 10 ? '0' + dateObject.getDate() : dateObject.getDate()}/${(dateObject.getMonth() + 1) < 10 ? '0' + (dateObject.getMonth() + 1) : (dateObject.getMonth() + 1)}/${dateObject.getFullYear()}` : Endpoints.getBookings + `?status=all&bookingData=athelete`
                let resp = await FetchApi(postURL);
                if (resp && resp.type === "success") {
                    let data = resp.data;
                    data.forEach((item) => {
                        const booked_date = new Date(item.availability_date)
                        item['bookedDate'] = `${booked_date.getDate()} ${monthNames[booked_date.getMonth()]} ${booked_date.getFullYear()}`
                        item['start'] = item.time_slot ? formatTime(item.time_slot, true) : ''
                        item['end'] = item.time_slot_end ? formatTime(item.time_slot_end, true) : ''
                        if (props.user && props.user.user_role && props.user.user_role.name === 'Facilities') {
                            item['image'] = item.user.image
                            item['name'] = item.user.name 
                            item['user_sports'] = item.user.user_sports.name
                            item['user_star'] = item.user.reviews_rating;
                            item['address'] = item.user.address
                        }
                        else if (props.user && props.user.user_role && props.user.user_role.name === 'Coach') {
                            item['image'] = item.user.image
                            item['name'] = item.user.name
                            item['user_sports'] = item.user.user_sports.name
                            item['user_star'] = item.user.reviews_rating;
                            item['address'] = item.user.address
                        }
                        else {
                            item['image'] = item.coach_facility.image
                            item['name'] = item.coach_facility.name
                            item['user_sports'] = item.coach_facility.user_sports.name
                            item['user_star'] = item.coach_facility.reviews_rating;
                            item['address'] = item.coach_facility.address
                        }
                    })
                    //setScheduleList([...scheduleList,...resp.data])
                    return new Promise((resolve, reject) => {
                        resolve(resp.data)
                    })
                }
            }
            catch (e) {
                return new Promise((resolve, reject) => {
                    resolve([])
                })
            }
        }
        else {
            return new Promise((resolve, reject) => {
                resolve([])
            })
        }

    }
    useEffect(() => {
        Promise.all([getScheduleList(), getScheduleListByAt()]).then((values) => {
            if (props.user && props.user.user_role && (props.user.user_role.name != 'Facilities' && props.user.user_role.name != 'Coach')){
                setScheduleList([...values?.[0]])
            }
            else{
                setScheduleList([...values?.[1]])
            }
            // setScheduleList([...values?.[0], ...values?.[1]])
            // setScheduleList([...values?.[1]])
        });

        // Promise.all([getScheduleListByAt()]).then((values) => {
        //     setScheduleList([...values?.[0]])
        // });
    }, []);

      const navigateUserHome = async (user) => {

		try {
			let resp = await FetchApi(Endpoints.userDetail, { slug: user?.coach_facility?.slug });
			if (resp && resp.type === 'success') {
				const userInfo = resp.data;
				userInfo['viewOnly'] = true;

                if (props.user.user_role.name === 'Coach' || props.user.user_role.name === 'Facilities') {
                    history('/user-details/'+ user?.user?.slug); 
                } else {
                    if (props.user.user_role.name === 'Coach') {
                        history('/coach-details/'+user?.coach_facility?.slug);
                    } else if (userInfo.user_role.name === 'Facilities') {
                        history('/facilities-details/'+user?.coach_facility?.slug);
                    }   
                }
			}
		} catch (e) {
			if (e && e.response && e.response.message && e.response.message.email) {
				Swal.fire({
					text: 'Failed to delete the post',
					icon: 'error',
				});
			}
		}
	};

    useEffect(()=>{
      const NewscheduleList  = scheduleList.filter((item)=> item?.status == "confirmed" || item?.status == "completed");
    //   console.log(NewscheduleList)
      setUpdatedScheduleList(NewscheduleList);
    }, [scheduleList]);

    console.log('upppdddd___', updatedScheduleList);
    

    return (
        <div 
        style={{
            minHeight: '65vh'
        }}
        // className="main bg-primary"
        >
            <div
            style={{
                width: '98%'
              }} 
            className="p-2 pt-4 schedule-user"
            // className="container py-5 schedule-user"
            >
                <div 
                className="d-sm-flex pb-3 gap-3 align-items-center"
                >
                <div className="col-lg-12 col-md-12 custom-calendar">
                <h1 className="fs-24 medium pb-3 ps-4">My Schedule
                    {/* <Link className="add-location-link text-green d-flex align-items-center" 
                    onClick={(e) => { 
                    setModalIsOpen(true);
                    }}>
                    <i className="far fa-share-alt"></i> 
                    <p className="ms-2">Share</p>
                    </Link> */}
                </h1>

                {/* {scheduleList?.length>0 && formattedData?.length>0 ? */}
                <Calendar
                className="mb-5"
                localizer={localizer}
                selectable
                events={formattedData || []}
                step={60}
                timeslots={1}
                startAccessor="start"
                endAccessor="end"
                style={{ height: 600 }}
                onView={handleViewChange}
                onSelectEvent={handleSelectEvent}
                view={currentView}
                showAllEvents={true}
                defaultDate={scheduleList?.length>0?
                    parseDate(scheduleList?.[0]?.availability_date, scheduleList?.[0]?.time_slot)
                    : new Date()
                }
                date={date}
                onNavigate={(date, view) => {
                    setDate(date);
                    // setCurrentView(view);
                  }}
                // min={new Date()}
                components={{
                    toolbar: CustomToolbar,
                    dateCellWrapper: CustomDateCellWrapper,
                    event: (props) => <CustomEvent {...props} view={currentView} />,
                }}
                />
            {/* //     :
            //     <p className="fs-16 pt-2 ps-4">
            //         No Schedule found
            //     </p>
            // } */}
                
            </div>
                    
                    
                    
                    {/* <h1 className="fs-24 medium text-darkgray">My Schedule</h1>
                    <div>
                        <input 
                        type="date" 
                        min={new Date().toISOString().split('T')[0]}
                        className="rounded-8 custom-date" onChange={(e) => {
                            const value = e.target.value;
                            Promise.all([getScheduleList(value), getScheduleListByAt(value)]).then((values) => {
                                setScheduleList([...values[0], ...values[1]])
                            });
                        }} />
                    </div> */}
                </div>
                {/* <div className="row gx-md-3">
                    {scheduleList.map((item, i) => {
                        if(item?.status != "confirmed") return null;
                        return (
                            <div className="col-lg-4 col-md-6 mb-3" key={i}>
                                <div className="booking-box rounded-8 border overflow-hidden">
                                    <div className="bg-lightgreen d-flex p-3 rounded-8 justify-content-between">
                                        <span className="fs-16 semibold">
                                            <img
                                                src="assets/img/icon/calendar.svg"
                                                className="pe-2"
                                                alt=""
                                            />
                                            {item.bookedDate}
                                        </span>

                                        <span className="fs-16 semibold">
                                            <img
                                                src="assets/img/icon/clock.svg"
                                                className="pe-2"
                                                alt=""
                                            />
                                            {item.start} to {item.end}
                                        </span>
                                    </div>

                                    <div className="p-3 mt-1">
                                        <div className="d-flex align-items-center gap-2 mb-2">
                                            <img
                                                src={
                                                    item.image
                                                        ? item.image
                                                        : 'assets/img/edit-profile.png'
                                                }
                                                width="55"
                                                height="55"
                                                className="rounded-pill dp-border w-40 c-p"
                                                alt=""
                                                onClick={()=>{
                                                    navigateUserHome(item)
                                                }}
                                            />
                                            <div>
                                                <h6 className="fs-20 semibold c-p"
                                                 onClick={()=>{
                                                    navigateUserHome(item)
                                                }}
                                                >{item.name}</h6>
                                                <div className="fs-17 text-lightgray m-0 d-flex align-items-center gap-1">
                                                    {item.user_sports}
                                                    <div className="d-inline-flex">
                                                        <ReactStars
                                                            count={5}
                                                            onChange={(e) => { }}
                                                            size={20}
                                                            isHalf={true}
                                                            edit={false}
                                                            value={item.user_star ? parseFloat(item.user_star) : 0}
                                                            emptyIcon={<i className="far fa-star"></i>}
                                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                                            fullIcon={<i className="fa fa-star"></i>}
                                                            activeColor="#ffd700"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <p className="fs-16 pt-2">
                                            <i className="fa fa-map-marker-alt text-green pe-1 fs-16"></i>{" "}
                                            {item.address}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    {updatedScheduleList.length === 0 && 
                    <React.Fragment>
                        <div className="col-lg-4 col-md-6 mb-3">
                            <div className="booking-box rounded-8 border overflow-hidden">
                                <div className="p-3 mt-1">
                                    <p className="fs-16 pt-2">
                                        No Schedule found
                                    </p>
                                </div>
                            </div>
                        </div>
                    </React.Fragment>}
                </div> */}
            </div>

            <ShareModal
            show={modalIsOpen}
            onClose={()=>setModalIsOpen(false)}
            slug={'user'}
            />
        </div>
    );
}

const mapStateToProps = (state) => {
    const { user, loading, error } = state.Auth;
    return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, {})(Schedule);