import React, {useEffect} from "react";
import RatingView from "../../Layout/RatingView";
import ReactStars from "react-rating-stars-component";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { useState } from "react";
import SideBar from "../../Layout/SideBar";
import { Posts } from "../../Layout/Posts";
import { FetchApi } from "../../API/FetchApi";
import { Endpoints } from "../../API/Endpoints";
import Swal from "sweetalert2";
import ToastMessage from "../../Utils/ToastMessage";
import Promotions from "../Profile/Promotions";

function FacilityProfile(props) {
  const editProperty = { ...{ edit: true }, ...props }
  const [editProps, editPros] = useState(editProperty)
  const [user, setUserInfo] = useState()
  const [certificateList, setCertificateList] = useState([])
  const deleteItem = async (item) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        try {
          FetchApi(Endpoints.removeCertificateImage, { id: item.id }, 'POST').then((resp) => {
            if (resp && resp.status === "success") {
              let galleryItems = [...certificateList]
              setCertificateList(galleryItems.filter(i => i.id != item.id));
              ToastMessage.Success(resp.message);
              getUserInfo();
            }
            if (resp && resp.message && resp.message.email) {
              Swal.fire({
                text: "Failed to delete the post",
                icon: "error",
              });
            }
          })
        } catch (e) {
          if (e && e.response && e.response.message && e.response.message.email) {
            Swal.fire({
              text: "Failed to delete the post",
              icon: "error",
            });
          }
        }
      }
    });
  };
  useEffect(()=>{
    getUserInfo();
  }, []);
  const getUserInfo = async () => {
    try {
      let resp = await FetchApi(Endpoints.userDetail, {slug:props.user?.slug});
      if (resp && resp.type === "success") {
        setUserInfo(resp?.data);
        setCertificateList(resp?.data?.user_certificate);
      }
    }
    catch (e) {
      // console.log('AvailabilityList', JSON.stringify(e, null, 4));
    }
  };
  return (
    <div className="main py-4 ">
      <div className="container">
        <div className="row feed-page">
          <SideBar />
          {/* <!-- END LEFT COLUMN --> */}
          {user ? 
          <div className="col-lg-6 middle-area">
            <div className="border rounded-8 p-3">
              <div className="d-flex gap-3 mb-3 profile-info">
                <img
                  src={user?.image ? user?.image : '../assets/img/edit-profile.png'}
                  width="80"
                  height="80"
                  className="rounded-pill dp-border"
                  alt=""
                />
                <div>
                  <h5 className="semibold pb-1">{user?.name}</h5>
                  <div className="d-flex align-items-center gap-2 pb-1">
                    <span className="d-inline-flex">
                      <ReactStars
                        count={5}
                        onChange={(e) => { }}
                        size={20}
                        isHalf={true}
                        edit={false}
                        value={user?.reviews_rating ? parseFloat(user?.reviews_rating) : 0}
                        emptyIcon={<i className="far fa-star"></i>}
                        halfIcon={<i className="fa fa-star-half-alt"></i>}
                        fullIcon={<i className="fa fa-star"></i>}
                        activeColor="#ffd700"
                      />
                    </span>
                    <span className="fs-13 medium">
                      {user?.reviews_rating ? parseFloat(user?.reviews_rating) : 0}{" "}
                      <span className="text-lightgray regular">Ratings</span>{" "}
                      | {user?.user_reviews?.length > 100 ? '100+' : user?.user_reviews?.length}{" "}
                      <span className="text-lightgray regular">Reviews</span>
                    </span>
                  </div>
                  <div className="d-flex gap-2 pb-1 align-items-center">
                    {/* <h6 className="regular text-lightgray">{user?.facility_detail?.category?.name}</h6> */}
                    {/* <i className=" text-lightgray">&#8226;</i> */}
                    <p className="fs-12">
                      <i className="fa fa-map-marker-alt text-green pe-1 fs-16"></i>{" "}
                      {user?.address}
                    </p>
                  </div>

                  <div className="d-sm-flex align-items-center gap-2">
                    <p className="fs-12 m-0 pb-2">
                      <i className="fa fa-envelope text-green pe-1 fs-14"></i>{" "}
                      {user?.email}
                    </p>

                    <p className="fs-12 m-0 pb-2">
                      <i className="fa fa-phone text-green pe-1 ps-sm-2 fs-13"></i>{" "}
                      +{user.phone}
                    </p>
                  </div>

                  <Link className="butn py-2 fs-15" to={'/update-facility-profile'} state={editProps}>Edit Profile</Link>
                </div>
              </div>

              <div className="row pe-xl-5 m-0 profile-data">
                <div className="col-3 pb-3 ps-0">
                  <h3 className="semibold text-green d-flex align-items-center gap-1 ">
                    <img src="../assets/img/icon/clipboardtick.svg" alt="" />
                    {user?.bookedUniqueUserCount || 0} 
                    {/* {user?.user_booking_coach_facility_count + user?.user_bookings_count } */}
                  </h3>
                  <h6 className="regular text-green">Bookings</h6>
                </div>

                <div className="col-3 text-center pb-3 ps-0">
                  <h3 className="semibold text-green d-flex align-items-center gap-1 justify-content-center">
                    <img src="../assets/img/icon/documenttext.svg" alt="" />
                    {user?.user_posts_count }
                  </h3>
                  <h6 className="regular text-green">Posts</h6>
                </div>

                <div className="col-3 text-center pb-3">
                <Link to={"/manage-job"}>
                  <h3 className="semibold text-green d-flex align-items-center gap-1 justify-content-center">
                    <img src="../assets/img/icon/briefcase.svg" alt="" />
                    {user.active_user_jobs_count }
                  </h3>
                  <h6 className="regular text-green">Open Jobs</h6>
                  </Link>
                </div>
               
              </div>

              <h3 className="medium pb-1">About</h3>
              <p className="text-justify text-696969">
              {user.showFull ? user.about : user.about.slice(0, 140)} {user.about.length > 140 &&
                  <a onClick={(e) => {
                    e.preventDefault();
                    let newCoachInfo = { ...user }
                    newCoachInfo['showFull'] = !newCoachInfo.showFull;
                    setUserInfo(newCoachInfo)
                  }} className="text-green c-p"
                  style={{
                    textDecoration: "underline"
                  }}
                  >{user.showFull ? 'Read Less' : 'Read More'}</a>}
              </p>

              {/* <h3 className="medium pb-2">Specialities</h3>
              <ul className="taglist d-flex align-items-start gap-2 flex-wrap pb-3">
                {user.user_speciality.map((item, i) => {
                  return (<li key={i}>{item.speciality}</li>)
                })}
              </ul> */}
              <h3 className="medium pb-2">Amenities</h3>
              <ul className="taglist d-flex align-items-start gap-2 flex-wrap pb-3">
                {user.facility_selected_amenity.map((item, i) => {
                  return (<li key={i}>{item.amenity}</li>)
                })}
              </ul>

              {/* <h3 className="medium pb-2">Facilities</h3>
              <ul className="taglist d-flex align-items-start gap-2 flex-wrap pb-3">
                {user.facility_selected_facility.map((item, i) => {
                  return (<li key={i}>{item.facility}</li>)
                })}
              </ul> */}

              <h3 className="medium pb-2">Certificates</h3>
              <ul className="taglist d-flex align-items-start gap-2 flex-wrap pb-3 position-relative">
                {certificateList.map((item, i) => {
                  return (<li key={i} className="">
                    <div className="position-relative d-flex gap-2 align-items-center">
                    
                      <a href={item.image} target="_blank" className="d-flex gap-1 align-items-center">
                        <img src="../assets/img/icon/certificate.svg" alt="" />{item.image_name}
                      </a>
                      {/* <a onClick={(e) => {
                        e.preventDefault();
                        deleteItem(item)
                      }} className="gal-remove  m-2 me-3 ceri-gallery end-0 bg-white c-p">
                        <img src="../assets/img/icon/trash.svg" width="16" alt="" />
                      </a> */}
                    </div>
                  </li>)
                })
                }
              </ul>

              <div className="d-flex align-items-center justify-content-between">
                <h3 className="medium pb-2">Gallery</h3>
                <Link className="text-green pb-3 d-block" to={"/manage-gallery"}>
                  Manage Gallery
                </Link>
              </div>

              <div className="row gx-sm-1">
                <div className="col-sm-6 pb-sm-0 pb-2 mb-2">
                  {
                    user.facility_image.length > 0 &&
                    <img
                      src={user.facility_image[0].image}
                      className="rounded-3 w-100 h-100"
                      alt=""
                    />
                  }
                </div>
                <div className="col-sm-6 ">
                  <div className="row gx-sm-1">
                    {user.facility_image.map((item, i) => {
                      return i === 0 ? '' : <React.Fragment key={i}>
                        <div className="col-6 mb-1" >
                          <img
                            src={item.image}
                            className="rounded-3 w-100 h-100"
                            alt=""
                          />
                        </div>
                      </React.Fragment>
                    })}
                  </div>
                </div>
              </div>
            </div>
            <Posts user={user} pageFrom='facility-profile' />
            <div className="review-section">
              <RatingView pageFrom='profile' userInfo={user} />
            </div>
          </div>
          : "" }
          {/* <!-- END MIDDLE COLUMN --> */}

          <Promotions />
          {/* <div className="col-lg-3 right-sidebar">
            <h4 className="fs-20 medium ">Promotions</h4>
            <Link className="text-green pb-3 d-block">Create Promotions</Link>
            <div className="border rounded-8 p-2 promo-box mb-3">
              <div className="d-flex align-items-center gap-2 mb-2">
                <img
                  src="../assets/img/stadium.png"
                  width="40"
                  height="40"
                  className="rounded-pill w-40"
                  alt=""
                />
                <div>
                  <h6 className="fs-14 semibold">National Stadium</h6>
                  <p className="fs-13 text-lightgray m-0">Sponsored</p>
                </div>
              </div>

              <img
                src="../assets/img/big-stadium.png"
                className="w-100 rounded-8"
                alt=""
              />

              <div className="mt-2">
                <p className="fs-14 text-green">
                  Sed ut perspiciatis unde iste natus error sit voptatem
                  acantium
                </p>
              </div>
            </div>

            <div className="border rounded-8 p-2 promo-box mb-3">
              <div className="d-flex align-items-center gap-2 mb-2">
                <img
                  src="../assets/img/stadium.png"
                  width="40"
                  height="40"
                  className="rounded-pill w-40"
                  alt=""
                />
                <div>
                  <h6 className="fs-14 semibold">National Stadium</h6>
                  <p className="fs-13 text-lightgray m-0">Sponsored</p>
                </div>
              </div>

              <div className="">
                <p className="fs-14">
                  Sed ut perspiciatis unde iste natus error sit voptatem
                  acantium
                </p>
                <Link className="butn butn-green fs-13 py-2 px-3">
                  Get Offer
                </Link>
              </div>
            </div>

            <div className="border rounded-8 p-2 promo-box mb-3">
              <div className="d-flex align-items-center gap-2 mb-2">
                <img
                  src="../assets/img/stadium.png"
                  width="40"
                  height="40"
                  className="rounded-pill w-40"
                  alt=""
                />
                <div>
                  <h6 className="fs-14 semibold">Stadium for You</h6>
                  <p className="fs-13 text-lightgray m-0">Sponsored</p>
                </div>
              </div>

              <img
                src="../assets/img/big-stadium.png"
                className="w-100 rounded-8"
                alt=""
              />

              <div className="mt-2">
                <p className="fs-14 text-green">
                  Sed ut perspiciatis unde iste natus error sit voptatem
                  acantium
                </p>
              </div>
            </div>
          </div> */}
          {/* <!-- END RIGHT COLUMN --> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};
//export default Header;
export default connect(mapStateToProps, {})(FacilityProfile);
